import React from 'react';
import {createTheme, GlobalStyles, ThemeProvider as MuiThemeProvider} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import PoppinsLight from './static/Poppins-Light.ttf';
import PoppinsRegular from './static/Poppins-Regular.ttf';
import PoppinsMedium from './static/Poppins-Medium.ttf';
import PoppinsBold from './static/Poppins-Bold.ttf';


const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, monospace',
  },
  palette: {
    primary: {
      main: '#1A5FB2',
      light: '#3180DE',
      lighter: '#62A2EF',
      darker: '#195194',
    },
    secondary: {
      main: '#D93737',
    },
    neutral: {
      main: '#B4CAE4',
      light: '#C7D7EB',
      dark: '#818995',
      contrastText: '#2C3E54',
    },
    background: {
      default: '#EBF2FA',
      darker: '#F2F2F2'
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Poppins'), local('Poppins-Regular'), url(${PoppinsLight}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Poppins'), local('Poppins-Regular'), url(${PoppinsRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Poppins'), local('Poppins-Regular'), url(${PoppinsMedium}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Poppins'), local('Poppins-Regular'), url(${PoppinsBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }`,
    },
  },
});

const globalStyles = {
  strong: {fontWeight: 500}
}

const ThemeProvider = ({children}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline/>
      <GlobalStyles styles={globalStyles}/>
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
